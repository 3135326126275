import React from "react"

const TrackingFormParameters = () => {

  return (
    <div>
      <h1>Trackingform Parameters</h1>
    </div>
  )
}

export default TrackingFormParameters
